import i18n from "../../libs/i18n/index"; // import VueI18n instance

export default [
  {
    title: "Dashboard",
    route: "home",
    icon: "HomeIcon",
  },
  // {
  //     header: 'Apps & Pages',
  // },
  {
    title: "Invoices",
    route: "all-invoices",
    icon: "FileTextIcon",
    // children: [{
    //         title: 'All Invoices',
    //         route: 'all-invoices',
    //     },
    //     {
    //         title: 'Paid Invoices',
    //         route: 'paid-invoices',
    //     },
    //     {
    //         title: 'Unpaid Invoices',
    //         route: 'unpaid-invoices',
    //     },
    //     {
    //         title: 'Overdue Invoices',
    //         route: 'overdue-invoices',
    //     },
    //     {
    //         title: 'Notdue Invoices',
    //         route: 'notdue-invoices',
    //     },
    // {
    //     title: 'Ledger',
    //     route: 'ladger',
    // }
    // ]
  },
  {
    title: "Credit Notes",
    route: "credit-note",
    icon: "CreditCardIcon",
  },
  {
    title: "Payments",
    route: "account-statements",
    icon: "DollarSignIcon",
  },
];

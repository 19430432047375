<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <dark-Toggler class="d-none d-lg-block" />
      <div class="page-title-cls">
        <span>{{ $t($route.meta.pageTitle) }}</span>
      </div>
    </div>
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <div class="companyinfo-cls">
        <span class="company_name">{{ $t(companyname) }} / </span>
        <span> {{ debtorName }}</span>
      </div>
    </div>
    <!-- <color-scheme/> -->
    <locale />
    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ username }}
            </p>
            <span class="user-status">{{ $t("Admin") }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="require('@/assets/images/avatars/defaultavatar.png')"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span>{{ $t("Profile") }}</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>
            <a href="javascript:void(0)" @click="logout()">{{
              $t("logout")
            }}</a>
          </span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import Locale from "@core/layouts/components/app-navbar/components/Locale.vue";
import ColorScheme from "@core/layouts/components/app-navbar/components/ColorScheme.vue";
import axios from "axios";

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
    Locale,
    ColorScheme,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      username: localStorage.getItem("username"),
      companyname: "",
      mytoken: "",
      debtorName: localStorage.getItem("debtorname"),
    };
  },
  created() {
    console.log("navbar", this.debtorName);
    this.getCompanyinfo();
  },

  methods: {
    getCompanyinfo() {
      this.mytoken = localStorage.getItem("token");

      var config = {
        method: "get",
        url:
          "https://engine.netsupport.dk:7080/account/v1/getmasternamebc/" +
          this.mytoken,
      };
      axios(config)
        .then((response) => {
          console.log("response", response);
          if (response.data.token.status == true) {
            this.companyname = response.data.token.name.companyName;
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    logout() {
      this.mytoken = localStorage.getItem("token");
      // var config = {
      //   method: "get",
      //   url: "https://engine.netsupport.dk:7080/account/v1/logout/"+this.mytoken
      // };
      // axios(config)
      // .then((response) => {
      //   console.log(response);
      //   if(response.data.status == 'logout'){
      //     localStorage.setItem("token", '');
      //     localStorage.removeItem("username");
      //     localStorage.removeItem("loggedIn");
      //     this.$router.push({ name: "login" });
      //   }
      // })
      // .catch(function (error) {
      //   console.log(error);
      // });
      localStorage.setItem("token", "");
      localStorage.removeItem("username");
      localStorage.removeItem("debtorname");
      this.$router.push({ name: "login" });
    },
  },
};
</script>
<style scoped>
.page-title-cls {
  color: white;
  font-size: 18px;
  font-weight: bolder;
}
.companyinfo-cls {
  font-size: 18px;
  color: white;
  display: flex;
  align-items: center;
  font-size: 13px;
  padding: 4px;
  padding: 5px 12px;
  border: 1px solid white;
}

.companyinfo-cls span {
  font-size: 18px;
  font-weight: bolder;
}

.company_name {
  font-size: 13px;
}
</style>
